import {React, useEffect, useState} from 'react';
import axios from "axios";
 import Moment from 'moment';
 import "react-datepicker/dist/react-datepicker.css";
 import config from '../config'

 const URL = config.apiUrl


const UserHomeNewTest = (props) => {

    const [sattaListCity_yearly,sattaListCity_yearlyList] = useState([]);
    const [yearDate,setyearDate] = useState('2024');
    const [FetchData,setFetchData] = useState('resultE');
    

    useEffect(() => {
           
        getAnnounementListDaily();
  
     
      },[])

 

    const getAnnounementListDaily = async (date = yearDate,column=FetchData) => {
        //  let token = localStorage.getItem('loginToken')
          // setisLoading(true)
          const headers = {
              'Content-Type': 'application/json;charset=UTF-8',
              "Access-Control-Allow-Origin": "*",
              'secret-key': '#1234nirmaljeetdeveloper987654321#satta#free#react#**project123456789#'
              }
               await axios.get(URL+'/api/city/yearly/get?date='+date+'&column='+column+'',{
                   headers
              }).then((data) => { 
              // setisLoading(false)
             // console.log("==========PARM=========");
              
                 let rs = data.data;

          
                 sattaListCity_yearlyList(rs);
 

              }).catch((er) => {
                  if (er.response.status == 401) {
                    
                 
                  }
              })
          }

    
            const numbers = [];
          
            for (let i = 1; i <= 31; i++) {
              numbers.push(i);
            }


            const mystyle = {
             
                backgroundColor: "white",
               
              };

              
          return (
            
         

    <div className="recordTableCol mt-5 chartTableData">
    <div className="heading-container">
        
  
    <div className="bg-white p-3">
            <div className="selectDate">
                <label>Select Date</label>
                
                <div className="cal-input">
                    {/* <i className="icon-calendar"></i> */}
                <input type="text" className="form-control "
                                value={ yearDate }
                               
                            onChange={(e) => {
                                setyearDate(e.target.value)
                               // console.log(Moment(e.target.value).format('YYYY-MM'));
                               // console.log(e.target.value) 
                              getAnnounementListDaily(e.target.value,FetchData)
                            }}/>

<select   className="form-control " onChange={(e) => {
                                setFetchData(e.target.value)
                               // console.log(Moment(e.target.value).format('YYYY-MM'));
                                // console.log(e.target.value) 
                               getAnnounementListDaily(yearDate,e.target.value)
                            }} >
             <option value="resultE">Nva Savera</option>
             <option value="resultF">Savera</option>
             <option value="resultG">Super king</option>
             <option value="resultH">Shree ganesh</option>
             <option value="resultB">Fridabad</option>
             <option value="resultI">New faridabad</option>
             <option value="resultC">Gaziyabad</option>
             <option value="resultD">Gali</option>
             <option value="resultA">Disawer</option>

        
                            </select>


                {/* <input type="text" value="21-11-2023" readonly></input> */}
                </div>
            </div>

</div>


    </div>
    <div className="chartTable">
        <table className="table table-striped">
            <thead>
                <tr>
                    <th>{sattaListCity_yearly.year}</th>
                    { sattaListCity_yearly?.monthlyData?.map((ls,i) => {
                          
                            return (
                        
                            <th className='ns-city-table hight'>{ ls.monthName }</th>
                        
                        )
                        }) }
                   
                </tr>
  {numbers.map((number) => (
          <tr style={mystyle} key={number}>

<td>{number}</td>
{/* {console.log(sattaListCity_yearly[number])} */}
 
{ sattaListCity_yearly?.monthlyData?.map((ls,i) => {
                          
                          const valuesArray = ls.data[number]?.[FetchData];

                  
                        
                          
                            return (

                                <td className='ns-city-table hight'>{  valuesArray }</td>
                              
                            )
                         

                          
                      }) }


          </tr>

        ))}





{/* {numbers.map((number) => (
          <tr key={number}>

{ 
 sattaListCity_yearly[number]?.data
}


          </tr>

        ))} */}

        

            </thead>
            <tbody>
               
          

            </tbody>
        </table>
    </div>
</div>
);



}

export default UserHomeNewTest;
