import {React, useEffect, useState} from 'react';
import axios from "axios";
import { Navigate, Link } from "react-router-dom"
// import { Table, Nav ,Spinner, Modal, Button, Form} from 'react-bootstrap'
import Moment from 'moment';
import config from '../config'
// import { Link } from 'react-router';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCoffee, faEdit } from '@fortawesome/free-solid-svg-icons'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import '../AppNew.css';


//  for icons
// npm i --save @fortawesome/fontawesome-svg-core
// npm install --save @fortawesome/free-solid-svg-icons
// npm install --save @fortawesome/react-fontawesome

 

const About = (props) => {

    const [sattaListCity_yearly,sattaListCity_yearlyList] = useState([]);
    const [yearDate,setyearDate] = useState('2024');
    const [FetchData,setFetchData] = useState('resultA');
    

    const [isLoading,setisLoading] = useState(true);
    const [sattaList, setSattaList] = useState([]);
    const [sattaListCity, setSattaListCity] = useState([]);
    const [sattaListCity_last, setSattaListCity_last] = useState([]);
    

    const [siteTitle,setSiteTitle] = useState('Nva Savera');
    const [siteAnnouncement,setSiteAnnouncement] = useState('');

    const [siteTitle2,setSiteTitle2] = useState('');
    const [siteAnnouncement2,setSiteAnnouncement2] = useState('');


    const [siteTitle3,setSiteTitle3] = useState('');
    const [siteAnnouncement3,setSiteAnnouncement3] = useState('');

    const [setDailyRecords1,setDailyRecords] = useState([]);
    
    



    

    function getNowDateTime() {
        const today = new Date();
        const month = today.toLocaleString('default', { month: 'long' });
        

        const year = today.getFullYear();
        const date = today.getDate();


        const analogue = today.toLocaleString('en-US', { hour: 'numeric',minute: 'numeric', hour12: true });
       // const min =  today.toLocaleString('en-US', { minutes: 'numeric', hour12: true });
        

        return `${month}`;
      }


    function getNowTime() {
        const today = new Date();
        const month = today.toLocaleString('default', { month: 'long' });
        

        const year = today.getFullYear();
        const date = today.getDate();


        const analogue = today.toLocaleString('en-US', { hour: 'numeric',minute: 'numeric', hour12: true });
       // const min =  today.toLocaleString('en-US', { minutes: 'numeric', hour12: true });
        

        return `${date}, ${year} ${analogue}`;
      }
      



    const [adminno,setadminno] = useState('');
    let ab = 0;

    // const URL = 'http://localhost:3003'
    // const URL = 'https://satta-backend.herokuapp.com'
    // const URL = 'https://44.207.30.122:8000'
    const URL = config.apiUrl
    
    const [nsdate,setNsdate] = useState('');

    // const month = nsdate.date.toLocaleString("en-us", { month: "long" });
    // const day = nsdate.date.toLocaleString("en-us", { day: "2-digit" });
    // const year = nsdate.date.getFullYear();
    //const month = Moment(nsdate).toLocaleString('en-us',{month:'long', year:'numeric'})

    const today = new Date(nsdate); // yyyy-mm-dd

// Getting short month name (e.g. "Oct")
const month = today.toLocaleString('default', { month: 'long' });
const year = today.toLocaleString('default', { year: 'numeric' });
 
const last_mon = new Date(today.setMonth(today.getMonth() - 1)); 

const last_month = last_mon.toLocaleString('default', { month: 'long' });
const last_year = last_mon.toLocaleString('default', { year: 'numeric' });



     const getAnnounement = async () => {
        let token = localStorage.getItem('loginToken')
        // setisLoading(true)
        const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            'secret-key': '#1234nirmaljeetdeveloper987654321#satta#free#react#**project123456789#'
            }
            await axios.get(URL+'/api/announcment',{
                 headers
            }).then((data) => { 
            // setisLoading(false)
               // console.log(data);
                let rs = data.data;
                setSiteTitle(rs.title || 'Nva Savera')
                setSiteAnnouncement(rs.description || '')

                setSiteTitle2(rs.title2 || 'Nva Savera')
                setSiteAnnouncement2(rs.description2 || '')

                setSiteTitle3(rs.title3 || 'Nva Savera')
                setSiteAnnouncement3(rs.description3 || '')

                 

                setadminno(rs.adminno || '')
            }).catch((er) => {
                if (er.response.status == 401) {
                   // console.log('getting eror ');
                   // console.log(er.response.status);
                }
            })
        }
  
     

    const getAnnounementListDaily = async () => {
        //  let token = localStorage.getItem('loginToken')
          // setisLoading(true)
          const headers = {
              'Content-Type': 'application/json;charset=UTF-8',
              "Access-Control-Allow-Origin": "*",
              'secret-key': '#1234nirmaljeetdeveloper987654321#satta#free#react#**project123456789#'
              }
              await axios.get(URL+'/api/get/daily/records',{
                   headers
              }).then((data) => { 
              // setisLoading(false)
             // console.log("==========PARM=========");
               
          
                 let rs = data.data;
                
                 let listolds = [];

              

                  listolds.push({ name: "Mahakaal", 'old': rs[0].resultN, 'new': rs[1].resultN, 'time': "01:00 PM" })
                  listolds.push({ name: "Savera", 'old': rs[0].resultF, 'new': rs[1].resultF, 'time': "02:30 PM" })

                  listolds.push({ name: "Nva Savera", 'old': rs[0].resultE, 'new': rs[1].resultE, 'time': "02:30 PM",'is_full':"Yes" })

                 
                // listolds.push({ name: "Super king", 'old': rs[0].resultG, 'new': rs[1].resultG, 'time': "04:30 PM" })
//                listolds.push({ name: "Jai Luxmi", 'old': rs[0].resultL, 'new': rs[1].resultL, 'time': "04:50 PM" })

                  listolds.push({ name: "Shree ganesh", 'old': rs[0].resultH, 'new': rs[1].resultH, 'time': "04:40 PM" })
                  listolds.push({ name: "Delhi Bajar", 'old': rs[0].resultK, 'new': rs[1].resultK, 'time': "03:10 PM" })

                  listolds.push({ name: "Faridabad", 'old': rs[0].resultB, 'new': rs[1].resultB, 'time': "06:10 PM",'is_full':"Yes"  })

                
                  listolds.push({ name: "Punjab Day", 'old': rs[0].resultJ, 'new': rs[1].resultJ, 'time': "05:10 PM" })
                  listolds.push({ name: "New faridabad", 'old': rs[0].resultI, 'new': rs[1].resultI, 'time': "06:40 PM" })

                  listolds.push({ name: "Gaziyabad", 'old': rs[0].resultC, 'new': rs[1].resultC, 'time': "09:00 PM" })

                 // listolds.push({ name: "Himalaya", 'old': rs[0].resultO, 'new': rs[1].resultO, 'time': "06:20 PM" })
                 // listolds.push({ name: "Himachal", 'old': rs[0].resultM, 'new': rs[1].resultM, 'time': "07:10 PM" })
               
                  
                  listolds.push({ name: "Gali", 'old': rs[0].resultD, 'new': rs[1].resultD, 'time': "12:00 PM" })

                 
                  listolds.push({ name: "Disawer", 'old': rs[0].resultA, 'new': rs[1].resultA, 'time': "05:00 AM",'is_full':"Yes" })

                

            // listolds.push({name:"",'old':"",'new':"",'time':""})
  
 
            //console.log(listolds);
                   const vips = listolds;

                  
               setDailyRecords(vips);
               
                

              }).catch((er) => {
                  if (er.response.status == 401) {
                    
                     // console.log('getting eror ');
                     // console.log(er.response.status);
                  }
              })
          }
           

      


        
 

  const getList = async (d = nsdate) => {
    let token = localStorage.getItem('loginToken')
    setisLoading(true)
    // 'Content-Type': 'application/json',
    const headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        'secret-key': '#1234nirmaljeetdeveloper987654321#satta#free#react#**project123456789#'
      }
     // console.log(d);
      if(!d || d === undefined)
      {
          d = makeTime()
      }
      await axios.get(URL+'/api/get?date='+d,{
          headers
      }).then((data) => { 
        setisLoading(false)
         // console.log(data.data);
          
        //   setSattaList(data.data)
          let sattaListData = [];
            // let startdate = new Date();
            let month = 0;
            // "2022-06-18T18:52:34.064Z"
            
            let startdate = Moment(d+'T10:00:00.000Z','YYYY-MM-DD hh:mm A'); 
           // console.log('startdate ===== >',startdate);
            for (let i = 0; i < 28; i++) {
                const findDate = filterData(data.data,startdate,'YYYY-MM-DD hh:mm A');
                if(findDate)
                {
                   // console.log(i,findDate);
                    sattaListData.push(findDate);
                }
                else
                {
                    const element = {
                        _id : '',
                        resultA : '',
                        resultB : '',
                        resultC : '',
                        resultDate : startdate,
                        resultDateTime : '',
                        createdAt : '',
                        updatedAt : '',
                    };
                    sattaListData.push(element);
                }
                startdate = Moment(startdate, "YYYY-MM-DD hh:mm A").add(20, 'minutes');
            }
            setSattaList(sattaListData)
          getListCities(d)
      }).catch((er) => {
        setisLoading(false)
         // console.log(er);
          if (er.response.status == 401) {
             // console.log('getting eror ');
             // console.log(er.response.status);
          }
      })
  }
  const filterData = (arr,search,format = 'YYYY-MM-DD') => {
      for (let i = 0; i < arr.length; i++) {
        //  // console.log(Moment(arr[i].resultDate).format('YYYY-MM-DD') +" == "+ Moment(search).format('YYYY-MM-DD'));
        if(Moment(arr[i].resultDate).format(format) == Moment(search).format(format))
        {
           // console.log('UTC ==>  ',arr[i].resultDate);
           // console.log('match ==>  ',Moment(arr[i].resultDate).format(format));
              
              return arr[i];
          }
      }
      return false;
  }
  const getListCities = async (d) => {
    let token = localStorage.getItem('loginToken')
    setisLoading(true)
    // 'Content-Type': 'application/json',
    const headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        'secret-key': '#1234nirmaljeetdeveloper987654321#satta#free#react#**project123456789#'
      }
     // console.log(d);
      if(d === undefined)
      {
          d = makeTime()
      }
      let newMonth = Moment(d).format('YYYY-MM')


      var ProData = "2023-09";
      await axios.get(URL+'/api/city/get?date='+ProData,{
        headers
    }).then((data) => {
      setisLoading(false)
          //// console.log(' ======================================================================');
          //// console.log('city');
          //// console.log(data.data.length);
          //// console.log(data.data);
          //// console.log(' ======================================================================');
          // let cityData = data.data;
          let cityData = [];
          // let startdate = data.data[data.data.length - 1].resultDate || new Date();
          let month = 0;
          // let startdate = newMonth+'-01T'
          let startdate = Moment(ProData+'-01T','YYYY-MM-DD'); 
          for (let i = 0; i <= 31; i++) {
              // let element;
              //// console.log('date starting ==> '+Moment(startdate).format('YYYY-MM-DD'));
              //// console.log('month == ',month);
              if(month == 0)
              {
                  month = Moment(startdate).format('MM');
                  //// console.log('month ==in if === ',month);
              }
              if(month != Moment(startdate).format('MM'))
              {
                  //// console.log('month == break',month);
                  break;
              }
              //// console.log('date entered ==> '+Moment(startdate).format('YYYY-MM-DD'));
              //// console.log('find data ==> '+Moment(startdate).format('YYYY-MM-DD'),filterData(data.data,startdate))
              //// console.log('find data ==> ',filterData(data.data,startdate))
              const findDate = filterData(data.data,startdate);
              if(findDate)
              {
                  //// console.log(i,findDate);
                  cityData.push(findDate);
              }
              else
              {
                  const element = {
                       _id : '',
                       resultA : '',
                       resultB : '',
                       resultC : '',
                       resultD : '',
                       resultE : '',
                       resultF : '',
                       resultDate : startdate,
                       resultDateTime : '',
                       createdAt : '',
                       updatedAt : '',
                   };

                   cityData.push(element);
              }
              startdate = Moment(startdate, "DD-MM-YYYY").add(1, 'days');
              // }
              // startdate = Moment(startdate, "DD-MM-YYYY").add(1, 'days');
          }
          setSattaListCity_last(cityData)
    }).catch((er) => {
       // console.log('city data === >  ',er);
        setisLoading(false)
        if(er.response)
        {
            if (er.response.status == 401) {
               // console.log('getting eror ');
               // console.log(er.response.status);
            }
        }
    })



      await axios.get(URL+'/api/city/get?date='+newMonth,{
          headers
      }).then((data) => {
        setisLoading(false)
            //// console.log(' ======================================================================');
            //// console.log('city');
            //// console.log(data.data.length);
            //// console.log(data.data);
            //// console.log(' ======================================================================');
            // let cityData = data.data;
            let cityData = [];
            // let startdate = data.data[data.data.length - 1].resultDate || new Date();
            let month = 0;
            // let startdate = newMonth+'-01T'
            let startdate = Moment(newMonth+'-01T','YYYY-MM-DD'); 
            for (let i = 0; i <= 31; i++) {
                // let element;
                //// console.log('date starting ==> '+Moment(startdate).format('YYYY-MM-DD'));
                //// console.log('month == ',month);
                if(month == 0)
                {
                    month = Moment(startdate).format('MM');
                    //// console.log('month ==in if === ',month);
                }
                if(month != Moment(startdate).format('MM'))
                {
                    //// console.log('month == break',month);
                    break;
                }
                //// console.log('date entered ==> '+Moment(startdate).format('YYYY-MM-DD'));
                //// console.log('find data ==> '+Moment(startdate).format('YYYY-MM-DD'),filterData(data.data,startdate))
                //// console.log('find data ==> ',filterData(data.data,startdate))
                const findDate = filterData(data.data,startdate);
                if(findDate)
                {
                    //// console.log(i,findDate);
                    cityData.push(findDate);
                }
                else
                {
                    const element = {
                         _id : '',
                         resultA : '',
                         resultB : '',
                         resultC : '',
                         resultD : '',
                         resultE : '',
                         resultF : '',
                         resultDate : startdate,
                         resultDateTime : '',
                         createdAt : '',
                         updatedAt : '',
                     };
 
                     cityData.push(element);
                }
                startdate = Moment(startdate, "DD-MM-YYYY").add(1, 'days');
                // }
                // startdate = Moment(startdate, "DD-MM-YYYY").add(1, 'days');
            }
          setSattaListCity(cityData)
      }).catch((er) => {
         // console.log('city data === >  ',er);
          setisLoading(false)
          if(er.response)
          {
              if (er.response.status == 401) {
                 // console.log('getting eror ');
                 // console.log(er.response.status);
              }
          }
      })
  }


  const getAnnounementListYearly = async (date = yearDate,column=FetchData) => {
    //  let token = localStorage.getItem('loginToken')
      // setisLoading(true)
      const headers = {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
          'secret-key': '#1234nirmaljeetdeveloper987654321#satta#free#react#**project123456789#'
          }
           await axios.get(URL+'/api/city/yearly/get?date='+date+'&column='+column+'',{
               headers
          }).then((data) => { 
          // setisLoading(false)
         // console.log("==========PARM=========");
          
             let rs = data.data;

      
             sattaListCity_yearlyList(rs);


          }).catch((er) => {
              if (er.response.status == 401) {
                
             
              }
          })
      }


        const numbers = [];
      
        for (let i = 1; i <= 31; i++) {
          numbers.push(i);
        }

        function updateMeta(title, description, keywords) {
          document.title = title;
        
          // Set description meta tag
          let descriptionTag = document.querySelector("meta[name='description']");
          if (!descriptionTag) {
            descriptionTag = document.createElement("meta");
            descriptionTag.setAttribute("name", "description");
            document.head.appendChild(descriptionTag);
          }
          descriptionTag.setAttribute("content", description);
        
          // Set keywords meta tag
          let keywordsTag = document.querySelector("meta[name='keywords']");
          if (!keywordsTag) {
            keywordsTag = document.createElement("meta");
            keywordsTag.setAttribute("name", "keywords");
            document.head.appendChild(keywordsTag);
          }
          keywordsTag.setAttribute("content", keywords);
        }


  useEffect(() => {
    getAnnounement();
    getAnnounementListDaily();
    setNsdate(makeTime())
    getAnnounementListYearly();
    //// console.log(Moment()._d);
    // if(nsdate !== undefined)
    // {
        // return false;
    // }
    getList();

    updateMeta(
      "Satta King | 786 Live Result | Satta Matka Result – Nva Savera",
      "Satta king result and Info about the 786 Lucky Number. If you want to check the record of satta matka live result for Punjab, Gali, Disawer, Faridabad.",
      ""
    );

  },[])

  const mystyle = {
             
    backgroundColor: "white",
   
  };

  const handleClick = () => {
    // Fetch the value of the input field
  
    getAnnounementListYearly(yearDate, FetchData);

  };







  const makeTime = () =>{
    const date = new Date();
   // console.log('moment date', Moment(date).format('YYYY-MM'))
    return Moment(date).format('YYYY-MM-DD')
    let year = date.getFullYear();
    let month = date.getMonth()
    let day = date.getDate()
    month++;
    month = (month < 10) ? '0'+month :month;
    day = (day < 10) ? '0'+day : day
    //// console.log(year+'-'+month+'-'+day);
    return year+'-'+month+'-'+day
  }
 
  return (
      <>
  
      <div className="container-fluid saraData">
        <div className="home-banner">
            <header className="header">
            <Link to="/">   <div className="companyName">
        <img src="images/logo.png" alt="Company Logo" />
        <h1>nvasaverasatta<span>.com</span></h1>
</div>      </Link>
                <div className="social-links ms-auto">
                    <div className="social-content">
                        <i className="icon-whatsapp"></i>
                        <div className="link-text">
                            <div className="chatOn greenTxt">Chat On</div>
                            {/* <div className="appTxt">What's App</div> */}
                        </div>
                    </div>
                    <div className="social-content">
                        <i className="icon-phone"></i>
                        <div className="link-text">
                            {/* <div className="chatOn orangeTxt">CALL US NOW</div> */}
                            <div className="appTxt"><a href="tel:+919914700000">9914700000</a></div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="banner-content">
                <div className="row">
                    <div className="col-md-6 kingTxt">
                        <img src="images/king.png" />
                    </div>
                    <div className="col-md-6 lady-img">
                        <img src="images/lady.png" />
                    </div>
                </div>
            </div>
            <img src="/images/banner-bg.png" />

            </div>





            <div className="bg-white p-3  ">

<div className='container'>

            <div className="selectDate">
                <label>Select Date</label>
                
                <div className="cal-input">
                    {/* <i className="icon-calendar"></i> */}
                <input type="date" className="form-control "
                                value={ nsdate }
                            onChange={(e) => {
                                setNsdate(e.target.value)
                               // console.log(Moment(e.target.value).format('YYYY-MM'));
                               // console.log(e.target.value) 
                                getList(e.target.value)
                            }}/>
                {/* <input type="text" value="21-11-2023" readonly></input> */}
                </div>
            </div>


            <div className="chartTable">
                          <table className="table table-striped">
                              <thead>
                                  <tr>
                                      <th>Date</th>
                                      <th>Mahakaal<span>(01:00 PM)</span></th>
                                      <th>Nva Savera<span>(02:30 PM)</span></th>
                                      <th>Savera<span>(02:30 PM)</span></th>
                                      <th>Delhi Bajar<span>(03:10 PM)</span></th>
                                      {/* <th>Super king<span>(4:30 PM)</span></th> */}
                                      <th>Shree ganesh<span>(04:40 PM)</span></th>
                                      {/* <th>Jai Luxmi<span>(04:50 PM)</span></th> */}
                                     

                                  </tr>
                              </thead>
                              <tbody>


                                  {sattaListCity.map((ls, i) => {
                                      ab = i + 1;
                                      return (
                                          <tr key={i}>
                                              {/* <td>{ i + 1}</td> */}
                                              {/* <td>{ ls.title }</td> */}
                                              {/* <td>{ ls.description }</td> */}
                                              <td className='ns-city-table hight'>{(!ls.resultDate) ? '-' : Moment(ls.resultDate).format('DD')}</td>
 
                                              <td className='columns-ns'>{ls.resultN} {/*Mahakaal*/}</td> 
                                             
                                              <td className='columns-ns'>{ls.resultE}{/* Nva Savera   */}</td>
                                              <td className='columns-ns'>{ls.resultF}{/* Savera  */}</td>
                                              <td className='columns-ns'>{ls.resultK}{/* Delhi Bajar  */}</td>
                                             {/* <td className='columns-ns'>{ls.resultG}  {Super king} </td>  */}
                                              <td className='columns-ns'>{ls.resultH}{/* Shree ganesh  */}</td>
                                              {/* <td className='columns-ns'>{ls.resultL}{Jai Luxmi }</td> */}
                                            



                                              {/* <td>
                                                <Link to={'/edit/'+ls._id}>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Link>
                                            </td> */}
                                          </tr>

                                      )
                                  })}

                              </tbody>
                          </table>
                          </div>
            <br></br>    <br></br>    <br></br>    <br></br>
            <div className="article-container">
      <h1 className="title">Understanding Satta King in India</h1>
      <p> A Closer Look at Punjab, Gali, Disawer, Faridabad, Ghaziabad</p>
      
      <section className="section">
        <h2 className="heading">History of Satta King Result?</h2>
        <p className="content">
          A common type of gambling in India is Satta King, which is rooted in the number-betting custom. It is a component of the larger, 1960s-era Satta Matka system. Satta Matka developed into a sophisticated system of wagering on random numbers. It originated as a lottery involving wagers on the opening and closing rates of cotton relayed from the New York Cotton Exchange to the Bombay Cotton Exchange.
        </p>
        <p className="content">
          "King" highlights the meaning of the game's winner, and "Satta" denotes wagering or gambling. This type of gambling has become especially well known in Punjab, Faridabad, Ghaziabad, Gali, Disawer, Himachal Pradesh, and the Himalayas over time. It has also spread to other areas.
        </p>
      </section>

      <section className="section">
        <h2 className="heading">How Does Satta King Work? And How to Become Famous in India?</h2>
        <p className="content">
          Satta King operates on a simple premise: participants choose a number from a set range (usually between 00 and 99). Once all the bets are placed, a random number is drawn, and the person who has bet on that number is declared the winner, often referred to as the "Satta King."
        </p>
      </section>

      <section className="section">
        <h2 className="heading">Legal Perspective</h2>
        <p className="content">
          Gambling laws in India are complex and vary by state. While games of skill are allowed under certain conditions, betting on random numbers, as in the case of Satta King, is generally prohibited. The Public Gambling Act of 1867 serves as the central law prohibiting public gambling houses, but enforcement varies.
        </p>
      </section>

      <section className="section">
        <h2 className="heading">Government Actions</h2>
        <p className="content">
          In recent years, governments in states like Haryana (Faridabad) and Uttar Pradesh (Ghaziabad) have intensified crackdowns on Satta King Operations. However, the clandestine nature of the game and its deep-rooted networks often make these efforts challenging.
        </p>
      </section>

      <section className="section">
        <h2 className="heading">How We Can Navigate to the Results</h2>
        <p className="content">
          Once on the homepage, look for a section labeled "Results" or "Satta King Live Results." This section is typically prominently displayed due to its popularity. Then if you want to view the result for the current month or the previous months. There you can see results for Mahakaal, Nva Savera, Delhi Bajar, Super King, Shree Ganesh, Jai Luxmi.
        </p>
      </section>

      

      <section className="section">
        <h2 className="heading">What is Nva Savera Satta?</h2>
        <p className="content">
          Satta King is a kind of lottery game based on numbers from 00 to 99 which comes under "Gambling". The real name of the game is Satta King, in which "Satta" means betting or gambling, and "Nva Savera" means a pot through which a number is drawn out. In the Nva Savera Satta game, people wager money on their chosen numbers from between 00 to 99. After which, a number is drawn out of the pot. Whichever person's number was drawn out, he would win the prize and people called him the Nva Savera Satta.
        </p>
      </section>

      <section className="conclusion">
        <h2 className="heading">Conclusion</h2>
        <p className="content">
          While Nva Savera Satta King continues to thrive in regions like Faridabad, Ghaziabad, UP, Himalaya, Himachal, Gali, Disawer, and Punjab, it is important to recognize the significant risks associated with this form of gambling. The allure of quick money comes at the cost of legal troubles, financial damage, and social degradation. As the government and law enforcement agencies work to curb this illegal activity, individuals need to understand the consequences and avoid such practices.
        </p>
        <p className="content">
          If you are focused on learning more about the legal connection of gambling in India or seeking help for gambling habits, a great number of resources are available to provide support and guidance.
        </p>
      </section>

      <footer className="footer">
         
        <p className="footer-content">
          We provide the latest updates on Satta King Results and information about the game for educational purposes. Our goal is to inform, not to encourage gambling.
        </p>
        <p className="legal-disclaimer">
          Legal Disclaimer: Nva Savera Satta King is illegal in many regions. We do not promote or encourage participation in illegal gambling activities. Please check the laws in your area and gamble responsibly.
        </p>
      </footer>
      
    </div>
    </div>
       

    </div>
    </div>
      </>
  );
}

export default About;
